// import React, { useState, useRef, useEffect } from "react";
// import { Filter } from "lucide-react";

// interface FilterButtonProps {
//   projectIds: string[];
//   priorities: string[];
//   statuses: { label: string; key: string }[];
//   onFilterChange: (filters: Filters) => void;
// }

// interface Filters {
//   projectIds: string[];
//   priorities: string[];
//   statuses: string[];
// }

// const FilterButton: React.FC<FilterButtonProps> = ({
//   projectIds,
//   priorities,
//   statuses,
//   onFilterChange,
// }) => {
//   const [isOpen, setIsOpen] = useState<boolean>(false);
//   const [filters, setFilters] = useState<Filters>({
//     projectIds: [],
//     priorities: [],
//     statuses: [],
//   });
//   const popoverRef = useRef<HTMLDivElement>(null);

//   const handleFilterChange = (filterType: keyof Filters, value: string) => {
//     setFilters((prevFilters) => {
//       const updatedFilters = { ...prevFilters };
//       const filterArray = updatedFilters[filterType];

//       if (filterArray.includes(value)) {
//         updatedFilters[filterType] = filterArray.filter(
//           (item) => item !== value
//         );
//       } else {
//         updatedFilters[filterType] = [...filterArray, value];
//       }

//       onFilterChange(updatedFilters);
//       return updatedFilters;
//     });
//   };

//   const appliedFiltersCount = Object.values(filters).flat().length;

//   useEffect(() => {
//     const handleClickOutside = (event: MouseEvent) => {
//       if (
//         popoverRef.current &&
//         !popoverRef.current.contains(event.target as Node)
//       ) {
//         setIsOpen(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   return (
//     <div className="relative inline-block" ref={popoverRef}>
//       <button
//         onClick={() => setIsOpen(!isOpen)}
//         className="flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//       >
//         <Filter className="mr-2 h-4 w-4" />
//         <span>Filters</span>
//         {appliedFiltersCount > 0 && (
//           <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
//             {appliedFiltersCount}
//           </span>
//         )}
//       </button>

//       {isOpen && (
//         <div className="absolute z-50 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
//           <div
//             className="py-1"
//             role="menu"
//             aria-orientation="vertical"
//             aria-labelledby="options-menu"
//           >
//             <div className="px-4 py-2 space-y-4">
//               <div className="space-y-2">
//                 <h4 className="text-sm font-medium text-gray-900">
//                   Project Name
//                 </h4>
//                 {projectIds.map((id) => (
//                   <label key={id} className="flex items-center cursor-pointer">
//                     <input
//                       type="checkbox"
//                       className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
//                       checked={filters.projectIds.includes(id)}
//                       onChange={() => handleFilterChange("projectIds", id)}
//                     />
//                     <span className="ml-2 text-sm text-gray-700">{id}</span>
//                   </label>
//                 ))}
//               </div>
//               <div className="space-y-2">
//                 <h4 className="text-sm font-medium text-gray-900">Priority</h4>
//                 {priorities.map((priority) => (
//                   <label
//                     key={priority}
//                     className="flex items-center  cursor-pointer"
//                   >
//                     <input
//                       type="checkbox"
//                       className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
//                       checked={filters.priorities.includes(priority)}
//                       onChange={() =>
//                         handleFilterChange("priorities", priority)
//                       }
//                     />
//                     <span className="ml-2 text-sm text-gray-700">
//                       {priority}
//                     </span>
//                   </label>
//                 ))}
//               </div>
//               <div className="space-y-2">
//                 <h4 className="text-sm font-medium text-gray-900">Status</h4>
//                 {statuses.map((status) => (
//                   <label
//                     key={status.key}
//                     className="flex items-center  cursor-pointer"
//                   >
//                     <input
//                       type="checkbox"
//                       className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
//                       checked={filters.statuses.includes(status.key)}
//                       onChange={() =>
//                         handleFilterChange("statuses", status.key)
//                       }
//                     />
//                     <span className="ml-2 text-sm text-gray-700">
//                       {status.label}
//                     </span>
//                   </label>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default FilterButton;

import React, { useState, useRef, useEffect } from "react";
import { Filter, ChevronDown, ChevronUp } from "lucide-react";

interface FilterButtonProps {
  projectIds: string[];
  priorities: string[];
  statuses: { label: string; key: string }[];
  onFilterChange: (filters: Filters) => void;
}

interface Filters {
  projectIds: string[];
  priorities: string[];
  statuses: string[];
}

const FilterButton: React.FC<FilterButtonProps> = ({
  projectIds,
  priorities,
  statuses,
  onFilterChange,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>({
    projectIds: [],
    priorities: [],
    statuses: [],
  });
  const [openAccordions, setOpenAccordions] = useState<{
    [key: string]: boolean;
  }>({
    projectIds: false,
    priorities: false,
    statuses: false,
  });
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleFilterChange = (filterType: keyof Filters, value: string) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      const filterArray = updatedFilters[filterType];

      if (value === "all") {
        updatedFilters[filterType] =
          filterArray.length === getOptionsLength(filterType)
            ? []
            : getAllOptions(filterType);
      } else if (filterArray.includes(value)) {
        updatedFilters[filterType] = filterArray.filter(
          (item) => item !== value
        );
      } else {
        updatedFilters[filterType] = [...filterArray, value];
      }

      onFilterChange(updatedFilters);
      return updatedFilters;
    });
  };

  const getOptionsLength = (filterType: keyof Filters) => {
    switch (filterType) {
      case "projectIds":
        return projectIds.length;
      case "priorities":
        return priorities.length;
      case "statuses":
        return statuses.length;
      default:
        return 0;
    }
  };

  const getAllOptions = (filterType: keyof Filters) => {
    switch (filterType) {
      case "projectIds":
        return projectIds;
      case "priorities":
        return priorities;
      case "statuses":
        return statuses.map((status) => status.key);
      default:
        return [];
    }
  };

  const toggleAccordion = (accordionName: string) => {
    setOpenAccordions((prev) => ({
      ...prev,
      [accordionName]: !prev[accordionName],
    }));
  };

  const appliedFiltersCount = Object.values(filters).flat().length;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const Accordion = ({
    title,
    name,
    children,
  }: {
    title: string;
    name: string;
    children: React.ReactNode;
  }) => (
    <div className="border-b border-gray-200 py-2">
      <button
        className="flex w-full items-center justify-between text-left"
        onClick={() => toggleAccordion(name)}
      >
        <h4 className="text-sm font-medium text-gray-900">{title}</h4>
        {openAccordions[name] ? (
          <ChevronUp className="h-4 w-4 text-gray-500" />
        ) : (
          <ChevronDown className="h-4 w-4 text-gray-500" />
        )}
      </button>
      {openAccordions[name] && <div className="mt-2">{children}</div>}
    </div>
  );

  return (
    <div className="relative inline-block" ref={popoverRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <Filter className="mr-2 h-4 w-4" />
        <span>Filters</span>
        {(appliedFiltersCount > 0 || isOpen) && (
          <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
            {appliedFiltersCount}
          </span>
        )}
      </button>

      {isOpen && (
        <div className="absolute z-50 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div className="px-4 py-2 space-y-4">
              <Accordion title="Project Name" name="projectIds">
                <label className="flex items-center cursor-pointer mb-2">
                  <input
                    type="checkbox"
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    checked={filters.projectIds.length === projectIds.length}
                    onChange={() => handleFilterChange("projectIds", "all")}
                  />
                  <span className="ml-2 text-sm text-gray-700">All</span>
                </label>
                {projectIds.map((id) => (
                  <label
                    key={id}
                    className="flex py-0.5 items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                      checked={filters.projectIds.includes(id)}
                      onChange={() => handleFilterChange("projectIds", id)}
                    />
                    <span className="ml-2 text-sm text-gray-700">{id}</span>
                  </label>
                ))}
              </Accordion>
              <Accordion title="Priority" name="priorities">
                <label className="flex items-center cursor-pointer mb-2">
                  <input
                    type="checkbox"
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    checked={filters.priorities.length === priorities.length}
                    onChange={() => handleFilterChange("priorities", "all")}
                  />
                  <span className="ml-2 text-sm text-gray-700">All</span>
                </label>
                {priorities.map((priority) => (
                  <label
                    key={priority}
                    className="flex py-0.5 items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                      checked={filters.priorities.includes(priority)}
                      onChange={() =>
                        handleFilterChange("priorities", priority)
                      }
                    />
                    <span className="ml-2 text-sm text-gray-700">
                      {priority}
                    </span>
                  </label>
                ))}
              </Accordion>
              <Accordion title="Status" name="statuses">
                <label className="flex items-center cursor-pointer mb-2">
                  <input
                    type="checkbox"
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    checked={filters.statuses.length === statuses.length}
                    onChange={() => handleFilterChange("statuses", "all")}
                  />
                  <span className="ml-2 text-sm text-gray-700">All</span>
                </label>
                {statuses.map((status) => (
                  <label
                    key={status.key}
                    className="flex py-0.5 items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                      checked={filters.statuses.includes(status.key)}
                      onChange={() =>
                        handleFilterChange("statuses", status.key)
                      }
                    />
                    <span className="ml-2 text-sm text-gray-700">
                      {status.label}
                    </span>
                  </label>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterButton;
