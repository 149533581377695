import { useState } from "react";
import { classNames } from "../../utils/string";
import { useNavigate } from "react-router-dom";
import AddModalDesign from "../../modal/AddModalDesign";
import { HStack } from "../../shared/utils";
import HomePageAssignedToMeTask from "./HomePageAssignedToMe";
import HomePageAssignedTask from "./HomePageAssignedTask";
import { useAuthStore } from "../../store/useAuthStore";
import { useGetOverview } from "../../queries/admin";
import useExtractTasks from "../myTask/ExtractTaskHook";
import useExtractMyTasks from "../myTask/useExtractMyTask";

export default function HomePage() {
  const navigate = useNavigate();
  const { data: userData } = useGetOverview();
  const { id: userId } = useAuthStore();
  const allTask = useExtractTasks(userData);
  const myTasks = useExtractMyTasks(userData, userId ?? "");
  const auth = useAuthStore();
  const [dialog, setDialog] = useState(false);
  const stats = [
    { name: "Open", value: allTask.filter(
      (task: any) =>
        task.status === "CREATED"
        
    ).length },
    {
      name: "In Progress",
      value: allTask.filter(
        (task: any) =>
          task.status === "INPROGRESS"
          
      ).length,
    },
    {
      name: "Completed",
      value: allTask.filter((task: any) => task.status === "COMPLETED").length,
    },
    {
      name: "Closed",
      value: allTask.filter((task: any) => task.status === "CLOSED").length,
    },
  ];


  return (
    <div className="flex flex-col gap-8">
      {dialog && (
        <AddModalDesign
          isOpen={dialog}
          onClose={() => {
            setDialog(false);
          }}
        />
      )}
      <dl className="w-full mx-auto grid grid-cols-4 gap-px bg-gray-900/5 border border-borderColor shadow-box rounded-xl  sm:grid-cols-4 lg:grid-cols-4">
        {stats.map((stat, index) => (
          <div
            key={stat.name}
            className={`flex cursor-pointer flex-wrap items-baseline justify-between gap-x-4 gap-y-0 bg-white px-4 sm:py-8 py-4 sm:px-6 xl:px-8
              ${
                stat.name === "Open"
                  ? "rounded-s-xl"
                  : stat.name === "Closed"
                  ? "rounded-e-xl"
                  : ""
              }
              `}
          >
            <dt className="font-medium sm:leading-6 leadin-4 text-gray-500 whitespace-nowrap sm:text-sm text-xs">
              {stat.name}
            </dt>

            <dd className="w-full flex-none sm:text-3xl text-xl font-medium leading-10 tracking-tight text-gray-900">
              {stat.value}
            </dd>
          </div>
        ))}
      </dl>
      <HStack className="gap-8 grid sm:grid-cols-2">
        <HomePageAssignedToMeTask task={myTasks?.assigneeTask ??[]}/>
        <HomePageAssignedTask task={myTasks?.assignerTask ?? []}/>
      </HStack>
    </div>
  );
}
