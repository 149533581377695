import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { SignInDetailsModel } from "../../types/CaptableLoginModel";
import { useAuthStore } from "../../store/useAuthStore";
import useIsMobile from "../../utils/detectDevice";
import { ButtonSize, PrimaryCTAButton } from "../../shared/CTAButtonComponents";
import { useCaptableSignIn } from "../../queries/login";
import { VStack, Error, HStack } from "../../shared/utils";
import { Input, Label } from "../../shared/InputField";
import { decodeAuthToken } from "../../utils/auth";
import { logoDarkImage } from "../project/TableIdConstants";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .required("Please enter valid email"),
  password: Yup.string().required("Password is required"),
});

const initialValues: SignInDetailsModel = {
  email: "",
  password: "",
};

export const loginPageImageUrl = ["/login.png", "/login2.png", "/login3.png"];

export default function CaptableLoginPage() {
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { mutate: captableSignIn } = useCaptableSignIn();

  const imageUrl = loginPageImageUrl;
  const length = 3;
  let count = 0;
  const { setAuth } = useAuthStore();

  useEffect(() => {
    //timer to change the login image on right side
    const intervalId = setInterval(() => {
      count++;
      setActiveIndex(count % length);
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const { isMobile } = useIsMobile(1025);

  return (
    <>
      {!isMobile ? (
        <div className="flex h-screen bg-white w-full">
          <div className="flex flex-col justify-between flex-1 px-4 pb-12 mx-auto sm:px-6 lg:flex-none  md:w-5/12 w-full">
            <div className="pt-[32px] ">
              <img
                className="h-12 mx-auto w-28"
                src={logoDarkImage}
                alt="hissa_logo"
              />
            </div>
            <div className="w-full max-w-sm lg:w-96 shadow-box lg:p-8 border border-borderColor rounded-xl mx-auto">
              <h2 className="text-center text-[28px] font-semibold tracking-tight text-[#181C32]">
                Sign In
              </h2>

              <div className="mt-8">
                <div className="mt-6">
                  <Formik
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      setLoading(true);

                      captableSignIn(
                        {
                          email: values.email,
                          password: values.password,
                        },
                        {
                          onSuccess: (data) => {
                            setLoading(false);

                            if (data.data.token) {
                              const accessToken = data.data.token;
                              const decodedJwt = decodeAuthToken(accessToken);

                              setAuth({
                                accessToken,
                                isAuthenticated: true,
                                email: decodedJwt.emailId,
                                name: decodedJwt.fullName,
                                id: decodedJwt.id,
                                role: "ADMIN",
                              });

                              navigate("/");

                              toast("Login Successful !", {
                                type: "success",
                                autoClose: 2000,
                              });

                              navigate("/home");
                            }
                          },
                          onError: (err: any) => {
                            setLoading(false);

                            const msg =
                              err.response.data.message ??
                              "Something went wrong, try again later";
                            toast(msg, {
                              type: "error",
                              autoClose: 2000,
                            });
                          },
                        }
                      );
                    }}
                  >
                    {(formik) => (
                      <Form>
                        <VStack className="gap-4">
                          <div>
                            <Label htmlFor="email">Email</Label>
                            <Input
                              id="email"
                              type="email"
                              autoComplete="email"
                              {...formik.getFieldProps(`email`)}
                            />
                            {formik.touched?.email && formik.errors?.email && (
                              <Error text={formik.errors?.email} />
                            )}
                          </div>
                          <div>
                            <Label htmlFor="password">Password</Label>

                            <Input
                              id="password"
                              type={`${showPassword ? "text" : "password"}`}
                              autoComplete="current-password"
                              {...formik.getFieldProps(`password`)}
                            />

                            {formik.touched?.password &&
                              formik.errors?.password && (
                                <Error text={formik.errors?.password} />
                              )}
                          </div>
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="comments"
                                aria-describedby="comments-description"
                                name="comments"
                                type="checkbox"
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor="comments"
                                className="font-medium text-gray-900"
                              >
                                Show Password
                              </label>
                            </div>
                          </div>
                          <div className="flex items-center justify-end">
                            {/* <div
                              event-name="Register User"
                              onClick={() => {
                                navigate("/signUp");
                              }}
                              className="text-sm font-semibold text-[#181C32] cursor-pointer"
                            >
                              Register User
                            </div> */}
                            <div
                              event-name="Forgot Password"
                              onClick={() => {
                                navigate("/forgotpassword");
                              }}
                              className="text-sm font-semibold text-[#EF5B2D] cursor-pointer"
                            >
                              Forgot Password?
                            </div>
                          </div>

                          <div className="mt-16">
                            <PrimaryCTAButton
                              event-name="Sign In button"
                              className="w-full text-center px-auto "
                              buttonSize={ButtonSize.MEDIUM}
                              loading={loading}
                              type="submit"
                            >
                              <p className="w-full text-base leading-0">
                                {" "}
                                Sign In
                              </p>
                            </PrimaryCTAButton>
                          </div>
                        </VStack>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <VStack className="md:w-7/12 md:visible invisible bg-gradient-to-br from-gradientFrom to-gradientTo justify-evenly">
            <VStack className="gap-8">
              <img
                className="inset-0 md:visible invisible object-cover mx-auto w-fit px-32"
                src={imageUrl[activeIndex]}
                alt=""
              />

              {activeIndex === 0 ? (
                <div className="px-10 pb-10 text-2xl font-semibold text-center text-white">
                  <p> Track tasks easily</p>
                  <p>see progress, spot holdups, and finish projects faster.</p>

                  <HStack className="gap-4 cursor-pointer mt-6 w-full justify-center">
                    <div
                      onClick={() => setActiveIndex(0)}
                      className="w-4 h-4 bg-white rounded-full"
                    ></div>
                    <div
                      onClick={() => setActiveIndex(1)}
                      className="w-4 h-4 bg-transparent border-2 border-white rounded-full"
                    ></div>

                    <div
                      onClick={() => setActiveIndex(2)}
                      className="w-4 h-4 bg-transparent border-2 border-white rounded-full"
                    ></div>
                  </HStack>
                </div>
              ) : activeIndex === 1 ? (
                <div className="px-10 pb-10 text-2xl font-semibold text-center text-white">
                  <p> Share, collect, and organize project files securely</p>
                  <p>all in one central hub</p>
                  <HStack className="gap-4 cursor-pointer mt-6 w-full justify-center">
                    <div
                      onClick={() => setActiveIndex(0)}
                      className="w-4 h-4 bg-transparent border-2 border-white rounded-full"
                    ></div>
                    <div
                      onClick={() => setActiveIndex(1)}
                      className="w-4 h-4 bg-white rounded-full"
                    ></div>
                    <div
                      onClick={() => setActiveIndex(2)}
                      className="w-4 h-4 bg-transparent border-2 border-white rounded-full"
                    ></div>
                  </HStack>
                </div>
              ) : (
                <div className="px-10 pb-10 text-2xl mx-auto font-semibold text-center text-white">
                  <p> Never miss deadlines</p>
                  <p>Automated reminders keep tasks on track</p>
                  <HStack className="gap-4 cursor-pointer mt-6 w-full justify-center">
                    <div
                      onClick={() => setActiveIndex(0)}
                      className="w-4 h-4 bg-transparent border-2 border-white rounded-full"
                    ></div>
                    <div
                      onClick={() => setActiveIndex(1)}
                      className="w-4 h-4 bg-transparent border-2 border-white rounded-full"
                    ></div>
                    <div
                      onClick={() => setActiveIndex(2)}
                      className="w-4 h-4 bg-white rounded-full"
                    ></div>
                  </HStack>
                </div>
              )}
            </VStack>
          </VStack>
        </div>
      ) : (
        <div className="flex h-screen bg-white w-full">
          <div className="flex flex-col justify-evenly flex-1 px-4 pb-12 mx-auto sm:px-6 lg:flex-none w-full">
            <div className="pt-[12px] ">
              <img
                className="h-12 mx-auto w-28"
                src={logoDarkImage}
                alt="hissa_logo"
              />
            </div>
            <div className="w-full max-w-sm lg:w-96 border border-gray-200 px-4 py-8 rounded-md shadow-box mx-auto">
              <h2 className="text-center text-[28px] font-semibold tracking-tight text-[#181C32]">
                Sign In
              </h2>

              <div className="mt-8">
                <div className="mt-6">
                  <Formik
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      setLoading(true);
                      captableSignIn(
                        {
                          email: values.email,
                          password: values.password,
                        },
                        {
                          onSuccess: (data) => {
                            const accessToken = data.data.token;
                            const decodedJwt = decodeAuthToken(accessToken);

                            setAuth({
                              accessToken,
                              isAuthenticated: true,
                              email: decodedJwt.emailId,
                              name: decodedJwt.fullName,
                              id: decodedJwt.id,
                              role: "ADMIN",
                            });

                            toast("Login Successful !", {
                              type: "success",
                              autoClose: 2000,
                            });

                            navigate("/home");
                          },
                          onError: (err: any) => {
                            setLoading(false);

                            const msg =
                              err.response.data.message ??
                              "Something went wrong, try again later";
                            toast(msg, {
                              type: "error",
                              autoClose: 2000,
                            });
                          },
                        }
                      );
                    }}
                  >
                    {(formik: any) => (
                      <Form>
                        <VStack className="gap-4">
                          <div>
                            <Label htmlFor="email">Email</Label>
                            <Input
                              id="email"
                              type="email"
                              autoComplete="email"
                              {...formik.getFieldProps(`email`)}
                            />
                            {formik.touched?.email && formik.errors?.email && (
                              <Error text={formik.errors?.email} />
                            )}
                          </div>
                          <div>
                            <Label
                              htmlFor="password"
                              className="block text-sm font-semibold text-[#181C32]"
                            >
                              Password
                            </Label>

                            <Input
                              id="password"
                              type={`${showPassword ? "text" : "password"}`}
                              autoComplete="current-password"
                              {...formik.getFieldProps(`password`)}
                            />

                            {formik.touched?.password &&
                              formik.errors?.password && (
                                <Error text={formik.errors?.password} />
                              )}
                          </div>
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="comments"
                                aria-describedby="comments-description"
                                name="comments"
                                type="checkbox"
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor="comments"
                                className="font-medium text-gray-900"
                              >
                                Show Password
                              </label>
                            </div>
                          </div>
                          <div className="flex items-center justify-end">
                            {/* <div
                              event-name="Register User"
                              onClick={() => {
                                navigate("/signUp");
                              }}
                              className="text-sm font-semibold text-[#181C32] cursor-pointer"
                            >
                              Register User
                            </div> */}
                            <div
                              event-name="Forgot Password"
                              onClick={() => {
                                navigate("/forgotpassword");
                              }}
                              className="text-sm font-semibold text-[#EF5B2D] cursor-pointer"
                            >
                              Forgot Password?
                            </div>
                          </div>
                          <div className="mt-16">
                            <PrimaryCTAButton
                              event-name="Sign In button"
                              className="w-full text-center px-auto "
                              buttonSize={ButtonSize.MEDIUM}
                              loading={loading}
                              type="submit"
                            >
                              <p className="w-full text-base leading-0">
                                {" "}
                                Sign In
                              </p>
                            </PrimaryCTAButton>
                          </div>
                        </VStack>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
}
