import React, { useMemo, useState } from "react";
import { ChevronRight, Clock, User, Flag, CheckCircle } from "lucide-react";
import {
  ChatBubbleLeftEllipsisIcon,
  DocumentIcon,
} from "@heroicons/react/24/outline";
import { formatDisplayDate } from "../../utils/date";
import { VStack } from "../../shared/utils";
import { extractDateTimeFromISO } from "../../utils/string";

export type History = {
  id: string;
  entityType: "TASK" | "STATUS" | "USER_ASSIGNMENT" | "COMMENT" | "DOCUMENT";
  action: string;
  oldValue: any;
  newValue: any;
  performedById: string;
  timestamp: string;
  relatedEntity: string | null;
  relatedEntityId: string | null;
  taskId: string;
  referenceId: string;
  userId: string;
  userName: string;
  referenceUserId?: string;
  referenceUserName?: string;
};

const getActivityIcon = (entityType: History["entityType"]) => {
  switch (entityType) {
    case "TASK":
      return <Flag className="w-5 h-5 text-blue-500" />;
    case "STATUS":
      return <CheckCircle className="w-5 h-5 text-green-500" />;
    case "USER_ASSIGNMENT":
      return <User className="w-5 h-5 text-purple-500" />;
    case "COMMENT":
      return <ChatBubbleLeftEllipsisIcon className="w-5 h-5 text-purple-500" />;
    case "DOCUMENT":
      return <DocumentIcon className="w-5 h-5 text-purple-500" />;

    default:
      return <ChevronRight className="w-5 h-5 text-gray-500" />;
  }
};

const getActivityDescription = (activity: History): string => {
  switch (activity.entityType) {
    case "TASK":
      if (activity.action === "UPDATED") {
        const changedField = Object.keys(activity.newValue.newValue)[0];
        const newValue = activity.newValue.newValue[changedField];
        return `Task ${changedField} updated to "${newValue}"`;
      }
      return `Task ${activity.action.toLowerCase()}`;
    case "STATUS":
      return `Status changed to ${activity.newValue.newValue}`;
    case "USER_ASSIGNMENT":
      return `${activity.referenceUserName} ${(
        activity.newValue.newValue as string
      ).toLowerCase()}`;
    case "COMMENT":
      if (activity.action === "CREATED") {
        return `Comment added: "${activity.newValue.newValue}"`;
      } else if (activity.action === "UPDATED") {
        return `Comment updated from "${activity.oldValue.oldValue}" to "${activity.newValue.newValue}"`;
      } else {
        return `Comment ${activity.action.toLowerCase()}`;
      }
    case "DOCUMENT":
      if (activity.action === "CREATED") {
        return `Document added: "${activity.newValue.newValue}"`;
      } else if (activity.action === "UPDATED") {
        return `Document updated from "${activity.oldValue.oldValue}" to "${activity.newValue.newValue}"`;
      } else {
        return `Document ${activity.action.toLowerCase()}`;
      }
    default:
      return `${activity.entityType} ${activity.action.toLowerCase()}`;
  }
};

const ActivityItem: React.FC<{ activity: History }> = ({ activity }) => (
  <div className="flex items-center space-x-4 p-4 hover:bg-gray-50 transition-colors duration-150 ease-in-out">
    <div className="flex-shrink-0">{getActivityIcon(activity.entityType)}</div>
    <div className="flex-grow">
      <p className="text-sm font-medium text-gray-900">
        {getActivityDescription(activity)}
      </p>
      <p className="text-xs text-gray-500">{activity.userName}</p>
    </div>
    <VStack className="items-end whitespace-nowrap">
      <div className="flex-shrink-0 text-xs text-gray-400">
        <Clock className="w-4 h-4 inline-block mr-1" />
        {formatDisplayDate(activity.timestamp)}
      </div>
      <div className="flex-shrink-0 text-xs text-gray-400">
        {extractDateTimeFromISO(activity.timestamp).time}
      </div>
    </VStack>
  </div>
);

export const FilterBadge: React.FC<{
  type: any | "ALL";
  isActive: boolean;
  onClick: () => void;
}> = ({ type, isActive, onClick }) => (
  <button
    onClick={onClick}
    className={`px-3 py-1 cursor-pointer whitespace-nowrap rounded-full text-xs font-semibold mr-2 mb-2 ${
      isActive
        ? "bg-blue-500 text-white"
        : "bg-gray-200 text-gray-700 hover:bg-gray-300"
    }`}
  >
    {type}
  </button>
);

const ActivitiesSection: React.FC<{ activities: History[] }> = ({
  activities,
}) => {
  const [filter, setFilter] = useState<any>(["ALL"]);

  const filteredActivities = useMemo(() => {
    return filter.includes("ALL")
      ? activities
      : activities.filter((activity) => filter.includes(activity.entityType));
  }, [activities, filter]);

  const entityTypes: (any | "ALL")[] = [
    "ALL",
    "TASK",
    "STATUS",
    "USER_ASSIGNMENT",
    "COMMENT",
  ];

  return (
    <div className="bg-white overflow-hidden">
      <div className="bg-gray-50 px-4 pt-5 pb-2 border-b border-gray-200">
        {/* <h2 className="text-lg font-semibold text-gray-800 mb-2">
          Recent Activities
        </h2> */}
        <div className="flex flex-wrap ">
          {entityTypes
            .filter(
              (type) =>
                activities.find((activity) => activity.entityType === type) ||
                type === "ALL"
            )
            .map((type) => (
              <FilterBadge
                key={type}
                type={type}
                isActive={filter.includes(type)}
                onClick={() => {
                  setFilter((prev: any) =>
                    prev.includes(type)
                      ? prev.filter((t: any) => t !== type)
                      : [...prev, type]
                  );
                  if (type !== "ALL") {
                    setFilter((prev: any) =>
                      prev.filter((t: any) => t !== "ALL")
                    );
                  }
                  if (type === "ALL" && filter.length > 1) {
                    setFilter(["ALL"]);
                  }
                }}
              />
            ))}
        </div>
      </div>
      <div className="divide-y divide-gray-200">
        {filteredActivities.map((activity) => (
          <ActivityItem key={activity.id} activity={activity} />
        ))}
      </div>
    </div>
  );
};

export default ActivitiesSection;
