import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import _, { assign } from "lodash";
import { HStack } from "../../shared/utils";
import { Input, TextArea } from "../../shared/InputField";
import { useCreateTask, useGetAllProject } from "../../queries/admin";
import { CubeIcon } from "@heroicons/react/24/outline";
import { Select } from "../../shared/Select";
import SearchAutoCompleteDropDown, {
  SwitchDropDownModel,
} from "./SearchInputBox";
import { useGetUserProjectRoles } from "../../queries/userRole";
import { UserProjectRoles } from "../../api/userRole";
import AssigneeComponent from "./Assignee";
import { X } from "lucide-react";
import { UserTaskRoleModel } from "../../types/TaskModel";
import { formatDate } from "../../utils/date";
import { ButtonSize, PrimaryCTAButton } from "../../shared/CTAButtonComponents";

interface DuplicateTaskModel {
  companyName: string;
  groupName: string;
  isRecurring: boolean;
  isGroupTask: boolean;
  taskType: "" | "INDIVIDUAL";
  creatorId: string;
  description: string;
  endDate: string;
  id: string;
  name: string;
  priority: string;
  projectId: string;
  projectName: string;
  projectSpecificStatusId?: string;
  startDate: string;
  status: string;
  tags: string[];
  recurringConfig: RecurringConfig[];
  userTaskRoles: UserDuplicateTaskRoleModel[];
}

export interface RecurringConfig {
  recurrenceInterval: number;
  startDateOffset: number;
  dueDate: number;
  recurrenceEndDate: string;
  createImmediately: boolean;
}

interface UserDuplicateTaskRoleModel {
  email: string;
  id: string;
  name: string;
  role: string;
  taskId: string;
  userId: string;
}

const SideBarAddTaskPanel = ({
  projectIdData,
  taskData,
  isOpen,
  onClose,
  onCreateProject,
}: {
  projectIdData?: any;
  taskData?: DuplicateTaskModel;
  isOpen: any;
  onClose: any;
  onCreateProject: any;
}) => {
  const { data: projectsData } = useGetAllProject();
  const [taskName, setTaskName] = useState(taskData?.name ?? "");
  const [intervalMonth, setIntervalMonth] = useState(3);
  const [recurringEndDate, setRecurringEndDate] = useState(30);
  const [recurringLastDate, setRecurringLastDate] = useState("");
  const [recurringStartDate, setRecurringStartDate] = useState(20);
  const [groupName, setGroupName] = useState(taskData?.groupName ?? "");
  const [projectId, setProjectId] = useState(projectIdData ?? "");
  const [manager, setManager] = useState("");

  const [priority, setPriority] = useState(taskData?.priority ?? "NORMAL");
  let newStartDate = taskData?.startDate
    ? `${formatDate(taskData?.startDate)}`
    : "";
  let newEndDate = taskData?.endDate ? `${formatDate(taskData?.endDate)}` : "";

  const [startDate, setStartDate] = useState(newStartDate);
  const [endDate, setEndDate] = useState(newEndDate);
  const [taskDescription, setTaskDescription] = useState(
    taskData?.description ?? ""
  );
  const { mutate: createTask } = useCreateTask();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const assigneeList = assignee.map((ele: any) => ({
      userId: ele.id,
      role: "ASSIGNEE",
    }));
    assigneeList.push({
      userId: manager,
      role: "ASSIGNER",
    });

    createTask(
      {
        name: taskName,
        groupName: groupName,
        taskType: isGroupTask ? "INDIVIDUAL" : "SHARED",
        isGroupTask: isGroupTask,
        isRecurring: isRecurring,
        recurringConfig: [
          {
            recurrenceInterval: intervalMonth,
            startDateOffset: recurringStartDate,
            dueDate: recurringEndDate,
            recurrenceEndDate: recurringLastDate ? new Date(recurringLastDate).toISOString() : "",
            createImmediately: false,
          },
        ],
        description: taskDescription,
        projectId: projectId,
        sendNotification: sendNotification,
        userTaskRoles: assigneeList,
        priority: priority,
        tags: [],
        startDate: startDate ? new Date(startDate).toISOString() : "",
        endDate: endDate ? new Date(endDate).toISOString() : "",
      },
      {
        onSuccess: (data) => {
          toast("Task Created Successfully", {
            type: "success",
            autoClose: 2000,
          });

          setTaskName("");
          setGroupName("");
          setProjectId("");
          setManager("");
          setPriority("NORMAL");
          setStartDate("");
          setEndDate("");
          setTaskDescription("");
          onCreateProject();
        },
        onError: (err: any) => {
          toast(err.response.data.message, {
            type: "error",
            autoClose: 2000,
          });
          onClose();
        },
      }
    );
    // onClose();
  };
  const [newAssignee, setNewAssignee] = useState("");
  const { data: userProjectRoles, refetch } = useGetUserProjectRoles(projectId);

  useEffect(() => {
    refetch();
  }, [projectId]);
  const [assignee, setAssignee] = useState<SwitchDropDownModel[]>([]);

  useEffect(() => {
    if (taskData) {
      const managerId =
        taskData?.userTaskRoles.find((ele) => ele.role === "ASSIGNER")
          ?.userId ?? "";
      setManager(managerId);
      const assigneeList =
        taskData?.userTaskRoles
          .filter((ele) => ele.role === "ASSIGNEE")
          .map((ele) => ({
            name: ele.name,
            type: ele.email,
            id: ele.userId,
          })) ?? [];
      setAssignee(assigneeList);
    }
  }, [taskData]);
  function createAssignee(value?: SwitchDropDownModel) {
    if (value?.type) {
      setAssignee([...assignee, value]);
    }
  }

  function deleteAssignee(value?: SwitchDropDownModel) {
    if (value?.id) {
      const newList = assignee.filter((ele) => ele.id !== value.id);
      setAssignee(newList);
    }
  }

  useEffect(() => {
    if (manager) {
      const newAssignee = assignee.filter((ele) => ele.id !== manager);
      setAssignee(newAssignee);
    }
  }, [manager]);

  const [sendNotification, setSendNotification] = useState(true);
  const [isGroupTask, setIsGroupTask] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-128">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => onClose()}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <Icon
                          icon="octicon:x-16"
                          className="text-white h-5 cursor-pointer"
                          height={20}
                        />
                      </button>
                    </div>
                  </Transition.Child>

                  <div className="w-full h-full overflow-y-auto bg-white px-4 pb-4 pt-5 sm:w-full sm:max-w-lg sm:p-6 to-indigo-50 flex flex-col shadow-xl rounded-lg overflow-hidden">
                    <HStack className="items-center justify-between w-full">
                      <HStack className="items-center">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                          <CubeIcon
                            className="h-6 w-6 text-indigo-600"
                            aria-hidden="true"
                          />
                        </div>
                        <Dialog.Title
                          as="h3"
                          className="text-base pb-2 pl-2 pt-2 font-semibold leading-6 text-gray-900"
                        >
                          {taskData ? "Duplicate Task" : "Create Task"}
                        </Dialog.Title>
                      </HStack>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-4">
                        {/* <button
                          onClick={handleSubmit}
                          className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                        >
                          {taskData ? "Duplicate Task" : "Create Task"}
                        </button> */}
                        <PrimaryCTAButton
                          // type="submit"
                          onClick={handleSubmit}
                          buttonSize={ButtonSize.MEDIUM}
                        >
                          {taskData ? "Duplicate Task" : "Create Task"}
                        </PrimaryCTAButton>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </HStack>
                    <div className="sm:flex sm:items-start ">
                      <div className="mt-3 text-center sm:ml-4 w-full sm:mt-0 sm:text-left">
                        <form onSubmit={handleSubmit} className="mt-4">
                          <div className="mb-4">
                            <label
                              htmlFor="projectName"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Task Name
                            </label>
                            <Input
                              type="text"
                              name="taskName"
                              id="taskName"
                              value={taskName}
                              onChange={(e) => setTaskName(e.target.value)}
                              required
                            />
                          </div>
                          <div className="mb-4">
                            <label
                              htmlFor="projectDescription"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Task Description
                            </label>
                            <TextArea
                              id="taskDescription"
                              name="taskDescription"
                              rows={3}
                              value={taskDescription}
                              onChange={(e) =>
                                setTaskDescription(e.target.value)
                              }
                              required
                            />
                          </div>

                          <div className="relative flex items-start pb-3">
                            <div className="flex h-6 items-center">
                              <input
                                id="groupTask"
                                aria-describedby="groupTask"
                                name="groupTask"
                                type="checkbox"
                                checked={isGroupTask}
                                onClick={() => {
                                  setIsGroupTask(!isGroupTask);
                                }}
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor="groupTask"
                                className="font-medium text-gray-900"
                              >
                                Group Task
                              </label>
                            </div>
                          </div>
                          {isGroupTask && (
                            <div className="mb-4">
                              <label
                                htmlFor="projectName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Group Name
                              </label>
                              <Input
                                type="text"
                                name="groupName"
                                id="groupName"
                                value={groupName}
                                onChange={(e) => setGroupName(e.target.value)}
                                required
                              />
                            </div>
                          )}
                          <div className="relative flex items-start pb-3">
                            <div className="flex h-6 items-center">
                              <input
                                id="isRecurring"
                                aria-describedby="isRecurring"
                                name="isRecurring"
                                type="checkbox"
                                checked={isRecurring}
                                onClick={() => {
                                  setIsRecurring(!isRecurring);
                                }}
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor="isRecurring"
                                className="font-medium text-gray-900"
                              >
                                Recurring Event
                              </label>
                            </div>
                          </div>
                          {isRecurring && (
                            <>
                              <div className="mb-4">
                                <label
                                  htmlFor="intervalMonth"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Interval Duration of Month
                                </label>
                                <Input
                                  type="number"
                                  name="intervalMonth"
                                  id="intervalMonth"
                                  value={intervalMonth}
                                  onChange={(e) =>
                                    setIntervalMonth(Number(e.target.value))
                                  }
                                  required
                                />
                              </div>
                              <HStack className="w-full gap-4">
                                <div className="mb-4  flex-1">
                                  <label
                                    htmlFor="projectName"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Recurring Due Date
                                  </label>
                                  <Input
                                    type="number"
                                    name="recurringEndDate"
                                    id="recurringEndDate"
                                    placeholder="Between 1 to 30"
                                    value={recurringEndDate}
                                    onChange={(e) =>
                                      setRecurringEndDate(
                                        Number(e.target.value)
                                      )
                                    }
                                    required
                                  />
                                </div>
                                <div className="mb-4 flex-1">
                                  <label
                                    htmlFor="projectName"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Recurring Offset Start Date
                                  </label>
                                  <Input
                                    type="number"
                                    name="recurringStartDate"
                                    id="recurringStartDate"
                                    placeholder="Between 1 to 30"
                                    value={recurringStartDate}
                                    onChange={(e) =>
                                      setRecurringStartDate(
                                        Number(e.target.value)
                                      )
                                    }
                                    required
                                  />
                                </div>
                              </HStack>
                              <div className="mb-4 w-full">
                                <label
                                  htmlFor="recurringLastDate"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Recuring Last Date
                                </label>
                                <Input
                                  type="date"
                                  name="recurringLastDate"
                                  id="recurringLastDate"
                                  value={recurringLastDate}
                                  onChange={(e) =>
                                    setRecurringLastDate(e.target.value)
                                  }
                                  required
                                />
                              </div>
                            </>
                          )}
                          {!isRecurring && (
                            <HStack className="w-full gap-8">
                              <div className="mb-4 w-full">
                                <label
                                  htmlFor="projectName"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Start Date
                                </label>
                                <Input
                                  type="date"
                                  name="startDate"
                                  id="startDate"
                                  value={startDate}
                                  onChange={(e) => setStartDate(e.target.value)}
                                  // required
                                />
                              </div>
                              <div className="mb-4 w-full">
                                <label
                                  htmlFor="projectName"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  End Date
                                </label>
                                <Input
                                  type="date"
                                  name="endDate"
                                  id="endDate"
                                  value={endDate}
                                  onChange={(e) => setEndDate(e.target.value)}
                                  // required
                                />
                              </div>
                            </HStack>
                          )}
                          <HStack className="w-full gap-8">
                            <div className="mb-4 w-full">
                              <label
                                htmlFor="projectName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Priority
                              </label>

                              <Select
                                placeholder="Select Priority"
                                options={[
                                  "NORMAL",
                                  "LOW",
                                  "MEDIUM",
                                  "HIGH",
                                  "CRITICAL",
                                ]}
                                value={priority}
                                onChange={(e) => setPriority(e.target.value)}
                              />
                            </div>
                            <div className="mb-4 w-full">
                              <div className="mb-4">
                                <label
                                  htmlFor="projectName"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Project Name
                                </label>
                                {projectsData && (
                                  <Select
                                    placeholder="Select Project"
                                    options={projectsData.map((ele: any) => ({
                                      id: ele.id,
                                      name: ele.name,
                                    }))}
                                    valueGetter={(o: any) => o.id}
                                    textGetter={(o: any) => o.name}
                                    value={projectId}
                                    onChange={(e) =>
                                      setProjectId(e.target.value)
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </HStack>
                          {projectsData && projectId && (
                            <div className="mb-4">
                              <label
                                htmlFor="projectName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Managed by
                              </label>
                              <Select
                                placeholder="Select Manager"
                                options={(userProjectRoles ?? []).map(
                                  (ele: UserProjectRoles) => ({
                                    userId: ele.userId,
                                    name: ele.name,
                                  })
                                )}
                                valueGetter={(o: any) => o.userId}
                                textGetter={(o: any) => o.name}
                                value={manager}
                                onChange={(e) => setManager(e.target.value)}
                              />
                            </div>
                          )}
                          {projectsData && projectId && (
                            <div className="mb-4">
                              <label
                                htmlFor="projectName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Assigned to
                              </label>
                              <div className="flex flex-wrap gap-2 mb-2">
                                {(assignee ?? []).map((roles, index) => (
                                  <span
                                    key={index}
                                    className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium flex items-center"
                                  >
                                    {roles.name}
                                    {
                                      <X
                                        className="w-4 h-4 ml-2 cursor-pointer"
                                        onClick={() => {
                                          deleteAssignee(roles);
                                        }}
                                      />
                                    }
                                  </span>
                                ))}
                              </div>
                              {(userProjectRoles ?? [])
                                .filter(
                                  (ele) =>
                                    !assignee.find(
                                      (ele2) =>
                                        ele2.id === ele.userId ||
                                        ele.userId === manager
                                    )
                                )
                                .map((user: UserProjectRoles) => ({
                                  name: user.name,
                                  type: user.email,
                                  id: user.userId,
                                })).length !== 0 && (
                                <div className="h-10">
                                  <SearchAutoCompleteDropDown
                                    placeholder="Add assignee"
                                    onChange={(e) => {
                                      setNewAssignee(e ?? "");
                                    }}
                                    data={
                                      (userProjectRoles ?? [])
                                        .filter(
                                          (ele) =>
                                            !assignee.find(
                                              (ele2) =>
                                                ele2.id === ele.userId ||
                                                ele.userId === manager
                                            )
                                        )
                                        .map((user: UserProjectRoles) => ({
                                          name: user.name,
                                          type: user.email,
                                          id: user.userId,
                                        })) as SwitchDropDownModel[]
                                    }
                                    getSelectedValue={createAssignee}
                                    selectedOption={
                                      {
                                        name: "",
                                        id: "",
                                        type: "",
                                      } as SwitchDropDownModel
                                    }
                                    field={"name" as keyof SwitchDropDownModel}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                          {projectsData && projectId && (
                            <div className="flex justify-between mt-2">
                              <div className="relative flex items-start">
                                <div className="flex h-6 items-center">
                                  <input
                                    id="notification"
                                    aria-describedby="notification"
                                    name="notification"
                                    type="checkbox"
                                    checked={sendNotification}
                                    onClick={() => {
                                      setSendNotification(!sendNotification);
                                    }}
                                  />
                                </div>
                                <div className="ml-3 text-sm leading-6">
                                  <label
                                    htmlFor="comments"
                                    className="font-medium text-gray-900"
                                  >
                                    Send Notification
                                  </label>
                                </div>
                              </div>
                              {/* <div
                              onClick={() => handleCancel("assignedTo")}
                              className="text-gray-800 border border-gray-700 ml-4 bg-white py-1 px-2 text-sm rounded-md"
                            >
                              Cancel
                            </div> */}
                            </div>
                          )}
                          <AssigneeComponent />
                        </form>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SideBarAddTaskPanel;
