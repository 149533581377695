import { useEffect, useState } from "react";
import { Outlet } from "react-router";
import {
  DialogPanel,
  Transition,
  TransitionChild,
  Dialog,
} from "@headlessui/react";
import {
  Bars3Icon,
  ChartPieIcon,
  HomeIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { classNames, limitString } from "../utils/string";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "../store/useAuthStore";
import NavLayout from "./NavLayout";
import CreateProjectModal from "../modal/ProjectCreateModal";
import { useGetAllProject } from "../queries/admin";
import { BookmarkPlus, Calendar, ChevronRight } from "lucide-react";
import SideBarAddTaskPanel from "../pages/myTask/SideBarAddTaskPanel";
import SwitchProduct from "../pages/myTask/SwitchProduct";
import { FlyoutProfile } from "../pages/aggrid/FlyoutProfile";
import { ButtonSize, PrimaryCTAButton } from "../shared/CTAButtonComponents";
import { Box } from "../shared/utils";
import Footer from "../shared/Footer";
import useIsMobile from "../utils/detectDevice";
import ProjectDownlinkComponent from "../pages/dashboard/SSENotification";
import { logoDarkImage, logoImage } from "../pages/project/TableIdConstants";
import Tooltip from "../shared/Tooltip";

export default function MainLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { data: projectsData } = useGetAllProject();

  const { role, name } = useAuthStore();
  useEffect(() => {
    if (pathname === "/") {
      if (role?.toUpperCase() === "ADMIN") {
        navigate("admin/user");
      } else {
        navigate("user/dashboard");
      }
    }
  }, [navigate, pathname, role]);
  const isMobile = useIsMobile();
  const { setAuth } = useAuthStore();
  const isAdmin = useAuthStore.getState().role === "ADMIN";

  const navigation = [
    {
      name: "Home",
      href: "/home",
      icon: HomeIcon,
      current: true,
      isAdmin: isAdmin,
    },
    {
      name: "My Tasks",
      href: "/tasks",
      icon: ChartPieIcon,
      current: false,
      isAdmin: true,
    },
    {
      name: "Calender",
      href: "/calender",
      icon: Calendar,
      current: false,
      isAdmin: true,
    },
  ];

  const mobileNavigation = [
    {
      name: "Home",
      href: "/home",
      icon: HomeIcon,
      current: true,
      isAdmin: isAdmin,
    },
    {
      name: "My Tasks",
      href: "/tasks",
      icon: ChartPieIcon,
      current: false,
      isAdmin: true,
    },
    {
      name: "Calender",
      href: "/calender",
      icon: Calendar,
      current: false,
      isAdmin: true,
    },
  ];

  useEffect(() => {
    if (projectsData) {
      const listOfProjects = projectsData.map((ele: any) => ({
        id: ele.id,
        name: ele.name,
        href: `/project/${ele.id}`,
        icon: ChevronRight,
        initial: ele.name[0],
        current: false,
      }));
      setResources(listOfProjects);
    }
  }, [projectsData]);

  const [resources, setResources] = useState<any[]>([]);

  const [isCreateProject, setCreateProject] = useState(false);
  const [isCreateTask, setCreateTask] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [sidebarExpanded, setSidebarExpanded] = useState(false);

  return (
    <div>
      {isCreateProject && (
        <CreateProjectModal
          isOpen={isCreateProject}
          onClose={() => {
            setCreateProject(false);
          }}
          onCreateProject={() => {
            setCreateProject(false);
          }}
        />
      )}
      {isCreateTask && (
        <SideBarAddTaskPanel
          isOpen={isCreateTask}
          onClose={() => {
            setCreateTask(false);
          }}
          onCreateProject={() => {
            setCreateTask(false);
          }}
        />
      )}

      <Transition show={sidebarOpen}>
        <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <TransitionChild
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </TransitionChild>

          <div className="fixed inset-0 flex">
            <TransitionChild
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </TransitionChild>

                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="h-8 w-auto"
                      src={logoDarkImage}
                      alt="hissa_logo"
                    />
                  </div>
                  <NavLayout
                    navigation={mobileNavigation}
                    resources={resources}
                    closeSideBar={() => setSidebarOpen(false)}
                  />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>

      <div
        className={`fixed hidden inset-y-0 left-0 z-50 md:flex flex-col transition-all duration-300 ease-in-out ${
          sidebarExpanded ? "w-60" : "w-16"
        } bg-white`}
        onMouseEnter={() => setSidebarExpanded(true)}
        onMouseLeave={() => setSidebarExpanded(false)}
      >
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-secondary px-4 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <div className="flex items-center justify-start h-8 gap-2 shrink-0">
              <img
                className="flex w-auto h-10 p-1 px-2 text-sm font-semibold leading-6 bg-white rounded-md cursor-pointer group gap-x-3 whitespace-nowrap"
                src="https://i.postimg.cc/8Cv31svZ/Screenshot-2023-12-02-133325-removebg-preview.png"
                alt="hissa_logo"
              />
              {sidebarExpanded && (
                <img
                  className="w-auto h-9 p-1"
                  src={logoImage}
                  alt="hissa_logo"
                />
              )}
            </div>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigation
                    .filter((nav) => nav.isAdmin)
                    .map((item) => (
                      <li key={item.name} className="cursor-pointer">
                        <button
                          //   href={item.href}
                          onClick={() => {
                            navigate(item.href);
                          }}
                          className={classNames(
                            item.href === pathname
                              ? "bg-primary text-white rounded-md"
                              : "text-white hover:bg-slate-400 rounded-md hover:text-white",
                            "group flex gap-x-3 p-3 text-sm font-semibold leading-6 w-full"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.href === pathname
                                ? "text-white"
                                : "text-white group-hover:text-white",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {sidebarExpanded && (
                            <span className="whitespace-nowrap">
                              {limitString(item.name, 16)}
                            </span>
                          )}
                        </button>
                      </li>
                    ))}
                </ul>
              </li>
              <li className="px-1">
                {sidebarExpanded ? (
                  <div className="text-xs pl-1 font-semibold leading-6 text-white whitespace-nowrap">
                    All Projects
                  </div>
                ) : (
                  <div className="text-xs pl-1 font-semibold leading-6 text-white">
                    All
                  </div>
                )}
                <ul className="-mx-2 mt-2 space-y-1">
                  {isAdmin &&
                    resources &&
                    resources.map((resource) => (
                      <li key={resource.name} className="cursor-pointer ">
                        <button
                          onClick={() => {
                            navigate(resource.href);
                          }}
                          className={classNames(
                            resource.href === pathname
                              ? "bg-primary text-white rounded-md"
                              : "text-white hover:bg-slate-400 rounded-md hover:text-white",
                            "group flex gap-x-3 justify-between rounded-md p-2 text-sm font-semibold leading-6 w-full"
                          )}
                        >
                          <div className="flex flex-row gap-x-3">
                            <span
                              className={classNames(
                                resource.href === pathname
                                  ? "border-indigo-600 text-gray-600"
                                  : "border-gray-200 text-gray-600 group-hover:border-indigo-600 group-hover:text-gray-600",
                                "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium"
                              )}
                            >
                              {resource.initial}
                            </span>
                            {sidebarExpanded && (
                              <Tooltip
                                _className="whitespace-nowrap z-50"
                                text={
                                  resource.name.length > 12 ? resource.name : ""
                                }
                              >
                                <span className="truncate">
                                  {limitString(resource.name, 12)}
                                </span>
                              </Tooltip>
                            )}
                          </div>
                          {sidebarExpanded && (
                            <resource.icon
                              className={classNames(
                                "text-white group-hover:text-white",
                                "h-5 w-5 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      </li>
                    ))}
                  {isAdmin && (
                    <li
                      key={"Create Projects"}
                      onClick={() => {
                        setCreateProject(true);
                      }}
                      className="px-4 cursor-pointer whitespace-nowrap hover:text-white text-sm font-semibold tracking-wide leading-5 text-white"
                    >
                      {" "}
                      {sidebarExpanded ? "+ Create Project" : "+"}
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div
        className={`flex-1 h-screen overflow-y-auto transition-all duration-300 ease-in-out ${
          sidebarExpanded ? "md:ml-60" : "md:ml-16"
        }`}
        id="outlet"
      >
        <div className="relative w-full min-h-screen">
          <div className={`sticky top-0 z-50`}>
            <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center bg-white shadow-box gap-x-4 px-4 sm:gap-x-6 sm:px-6 lg:px-8">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>

              <div
                className="h-6 w-px bg-gray-200 lg:hidden"
                aria-hidden="true"
              />

              <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                <form
                  className="relative flex flex-1"
                  action="#"
                  method="GET"
                ></form>
                <div className="flex items-center gap-x-4 lg:gap-x-6">
                  {/* <ThemeToggle /> */}
                  <ProjectDownlinkComponent />
                  <div className="hidden sm:block">
                    <SwitchProduct />
                  </div>
                  <PrimaryCTAButton
                    onClick={() => {
                      setCreateTask(true);
                    }}
                    buttonSize={ButtonSize.MEDIUM}
                  >
                    <span className="hidden lg:block">Add Task</span>
                    <BookmarkPlus className="w-4 h-4 ml-2" />
                  </PrimaryCTAButton>

                  <div
                    className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                    aria-hidden="true"
                  />
                  <div className="hidden lg:block">
                    <FlyoutProfile />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Box className="py-4 sm:px-8 px-4 pb-32 mx-auto">
            <Outlet />
          </Box>
          <div></div>
          <div className="absolute bottom-0 w-full mt-6 ">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
