import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { Icon } from "@iconify/react";

import { useAuthStore } from "../../store/useAuthStore";
import { HStack } from "../../shared/utils";

export default function FlyoutSwitch() {
  const [show, setShown] = useState(false);
  const isEsopExists = true;

  const solutions = [
    {
      name: "Cap Table",
      enabled: true,
      current: false,
      description: "Equity Ownership of Company",
      onClick: () => {},
      icon: (
        <div>
          <Icon
            icon="raphael:piechart"
            color="#E85936"
            width="30"
            height="30"
          ></Icon>
        </div>
      ),
    },
    {
      name: "ESOP",
      enabled: isEsopExists,
      current: false,
      description: "ESOP Management of Company",
      onClick: () => {},
      icon: (
        <div>
          <img
            className="h-8"
            src={isEsopExists ? "images/esop.svg" : "images/esop-grayed.svg"}
            alt="esop"
            color="#E85936"
          />
        </div>
      ),
    },
    {
      name: "Investor",
      enabled: isEsopExists,
      current: false,
      description: "Investor Portfolio Management",
      onClick: () => {},
      icon: (
        <div>
          <Icon
            icon="mdi:person-group"
            color="#E85936"
            width="30"
            height="30"
          ></Icon>
        </div>
      ),
    },
  ];

  return (
    <Popover className="relative">
      <Popover.Button
        onMouseEnter={() => {
          setShown(true);
        }}
        onMouseLeave={() => {
          setShown(false);
        }}
        onClick={() => setShown(!show)}
        className="text-gray-400 hover:text-gray-500"
      >
        <span className="sr-only">Switch</span>
        <Icon id="switch" icon="mdi:dots-grid" className={`h-6 w-6`} />
      </Popover.Button>

      <Transition
        show={show}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          onMouseEnter={() => {
            setShown(true);
          }}
          onMouseLeave={() => {
            setShown(false);
          }}
          className="absolute z-10 mt-5 flex w-screen max-w-max  -right-8"
        >
          <div className="w-screen max-w-xs flex-auto overflow-hidden rounded-lg bg-white text-sm leading-6 shadow-lg">
            <div className="bg-gray-50">
              <div
                key={"Switch"}
                className="flex items-center justify-center gap-x-1 p-2 font-semibold text-gray-900 hover:bg-gray-100"
              >
                {"Switch Platform"}
              </div>
            </div>

            <div className="p-4  cursor-pointer">
              {solutions.map((item) => (
                <div
                  key={item.name}
                  onClick={() => {
                    item.onClick();
                  }}
                  className="group relative flex gap-x-2 rounded-lg p-2 hover:bg-gray-50 mb-2"
                >
                  <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                    {item.icon}
                  </div>
                  <div className="flex-1">
                    <HStack className="justify-between">
                      <a
                        className={`font-semibold  ${
                          item.current
                            ? "text-primary"
                            : item.enabled
                            ? "text-gray-600 group-hover:text-primary"
                            : "text-gray-300"
                        }`}
                      >
                        {item.name}
                        <span className="absolute inset-0" />
                      </a>
                      {!item.enabled && (
                        <LockClosedIcon
                          className={`h-4 w-4 ${
                            item.current
                              ? "text-primary"
                              : item.enabled
                              ? "text-gray-600 group-hover:text-primary"
                              : "text-gray-300"
                          }`}
                          aria-hidden="true"
                        />
                      )}
                    </HStack>

                    <p
                      className={
                        item.enabled ? "text-gray-600" : "text-gray-300"
                      }
                    >
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
