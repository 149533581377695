import React, { useState, useEffect, useRef } from "react";
import { ChevronDown, Search, X } from "lucide-react";
import Tooltip from "../../shared/Tooltip";

const colorPalette = [
  "bg-red-100",
  "bg-blue-100",
  "bg-green-100",
  "bg-yellow-100",
  "bg-purple-100",
  "bg-pink-100",
  "bg-indigo-100",
  "bg-teal-100",
];

const textColorPalette = [
  "text-red-600",
  "text-blue-600",
  "text-green-600",
  "text-yellow-600",
  "text-purple-600",
  "text-pink-600",
  "text-indigo-600",
  "text-teal-600",
];

export function UserUpdateSelectComponent({
  users,
  onChange,
}: {
  users: any;
  onChange: any;
}) {
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef<any>(null);
  const searchInputRef = useRef<any>(null);

  const toggleUser = (userId: any) => {
    setSelectedUsers((prev: any) =>
      prev.includes(userId)
        ? prev.filter((id: any) => id !== userId)
        : [...prev, userId]
    );
  };

  useEffect(() => {
    onChange(selectedUsers);
  }, [selectedUsers, onChange]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setSearchTerm("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (isDropdownOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isDropdownOpen]);
  const filteredUsers = users.filter((user: any) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const visibleUsers = filteredUsers.slice(0, 3);
  const dropdownUsers = filteredUsers.slice(8);

  return (
    <div
      className="relative inline-block mx-auto w-full items-center"
      ref={dropdownRef}
    >
      <div className="flex py-2 flex-wrap items-start justify-start">
        {visibleUsers.length === 0 && (
          <div className="whitespace-nowrap">
            <div className="flex items-center text-sm text-gray-500">
              Not Assigned
            </div>
          </div>
        )}
        {visibleUsers.map((user: any, index: any) => (
          <UserUpdateAvatar
            key={user.id}
            user={user}
            isSelected={selectedUsers.includes(user.id)}
            onClick={() => toggleUser(user.id)}
            bgColor={colorPalette[index % colorPalette.length]}
            textColor={textColorPalette[index % textColorPalette.length]}
          />
        ))}

        {dropdownUsers.length > 0 && (
          <DropdownUpdateToggle
            count={dropdownUsers.length}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          />
        )}
      </div>

      {isDropdownOpen && (
        <DropdownUpdateMenu
          users={filteredUsers}
          selectedUsers={selectedUsers}
          toggleUser={toggleUser}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          searchInputRef={searchInputRef}
        />
      )}
    </div>
  );
}

export function UserUpdateAvatar({
  user,
  isSelected,
  onClick,
  bgColor,
  textColor,
}: {
  user: any;
  isSelected: any;
  onClick: any;
  bgColor: any;
  textColor: any;
}) {
  return (
    <div className="relative group sm:block hidden">
      <Tooltip
        text={user.name}
        _position="bottom-0"
        _className="bg-white/90 text-xs whitespace-nowrap z-100"
      >
        {/*  "right-0"
    | "left-0"
    | "right-1/2"
    | "bottom-0"
    | "left-1/2"
    | "bottom-1"
    | "bottom-4"
    | "-left-16"
    | "top-6 right-0"; */}
        <button
          onClick={onClick}
          className={`
          w-8 h-8 py-1 rounded-full flex text-xs items-center justify-center
          font-medium focus:outline-none
          transition-all duration-200 ease-in-out
          ${bgColor} ${textColor}
          ${isSelected ? "ring-2 ring-offset-2 ring-indigo-500" : ""}
        `}
        >
          {getInitials(user.name)}
        </button>
      </Tooltip>
      {/* <div className="absolute left-1/2 -translate-x-1 bottom-full mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
        {user.name}
      </div> */}
    </div>
  );
}

function DropdownUpdateToggle({
  count,
  onClick,
}: {
  count: any;
  onClick: any;
}) {
  return (
    <button
      onClick={onClick}
      className="w-8 h-8 rounded-full bg-gray-200 text-gray-700 hover:bg-gray-300 flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <ChevronDown size={18} />
    </button>
  );
}

function DropdownUpdateMenu({
  users,
  selectedUsers,
  toggleUser,
  searchTerm,
  setSearchTerm,
  searchInputRef,
}: {
  users: any;
  selectedUsers: any;
  toggleUser: any;
  searchTerm: any;
  setSearchTerm: any;
  searchInputRef: any;
}) {
  return (
    <div className="absolute top-full right-0 mt-2 w-72 bg-white rounded-md shadow-lg z-50 max-h-96 overflow-hidden flex flex-col">
      <div className="p-2 border-b">
        <div className="relative">
          <input
            ref={searchInputRef}
            type="text"
            className="w-full pl-8 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Search users"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          {searchTerm && (
            <button
              onClick={() => setSearchTerm("")}
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
            >
              <X className="h-5 w-5 text-gray-400 hover:text-gray-600" />
            </button>
          )}
        </div>
      </div>
      <div className="overflow-y-auto flex-grow">
        {users.map((user: any, index: any) => (
          <label
            key={user.id}
            className="flex items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
          >
            <input
              type="checkbox"
              checked={selectedUsers.includes(user.id)}
              onChange={() => toggleUser(user.id)}
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded mr-3"
            />
            <div className="flex items-center flex-grow">
              {/* <div
                className={`w-8 h-8 rounded-full ${
                  colorPalette[index % colorPalette.length]
                } ${
                  textColorPalette[index % textColorPalette.length]
                } flex items-center justify-center mr-3`}
              >
                {getInitials(user.name)}
              </div> */}
              <span className="text-sm text-gray-700">{user.name}</span>
            </div>
          </label>
        ))}
      </div>
    </div>
  );
}

function getInitials(name: any) {
  const value = name
    .split(" ")
    .map((n: any) => n[0])
    .join("");
  return value.substring(0, 1).toUpperCase();
}
