import { useMutation, useQuery } from "react-query";
import { handleEventForTracking } from "../analytics";
import { queryClient } from "./client";
import {
  createProjectDocument,
  createTaskDocument,
  deleteProjectDocument,
  deleteTaskDocument,
  downloadProjectFiles,
  downloadTaskFiles,
  generatePresignedUrlForProject,
  generatePresignedUrlForTask,
  getAllProjectDocuments,
  getAllTaskDocuments,
  uploadImportTemplate,
} from "../api/document";

export function useGetTaskDocuments(taskId: any) {
  return useQuery({
    queryKey: ["get-task-documents", taskId],
    queryFn: getAllTaskDocuments,
    select: (data) => data.data,
  });
}

export function useUploadImportTemplate() {
  return useMutation({
    mutationKey: "uploadImportTemplate",
    mutationFn: uploadImportTemplate,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "uploadImportTemplate",
        success: true,
        eventType: "API",
      });
    },
    onError: (error: {
      response: { data: { message: string; errors: string[] } };
    }) => {
      handleEventForTracking({
        eventName: "uploadImportTemplate",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useGetProjectDocuments(taskId: any) {
  return useQuery({
    queryKey: ["get-project-documents", taskId],
    queryFn: getAllProjectDocuments,
    select: (data) => data.data,
  });
}

export function useGetPresignedURLforTaskDocument() {
  return useMutation({
    mutationKey: "generate-presigned-url-task-document",
    mutationFn: generatePresignedUrlForTask,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "generate-presigned-url-task-document",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "generate-presigned-url-task-document",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useGetPresignedURLforProjectDocument() {
  return useMutation({
    mutationKey: "generate-presigned-url-Project-document",
    mutationFn: generatePresignedUrlForProject,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "generate-presigned-url-Project-document",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "generate-presigned-url-Project-document",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useCreateTaskDocument() {
  return useMutation({
    mutationKey: "create-task-document",
    mutationFn: createTaskDocument,
    onSuccess: () => {
      queryClient.invalidateQueries("get-task-documents");
      handleEventForTracking({
        eventName: "create-task-document",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "create-task-document",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useDownloadTaskDocument() {
  return useMutation({
    mutationKey: "download-task-document",
    mutationFn: downloadTaskFiles,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "download-task-document",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "download-task-document",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useDownloadProjectDocument() {
  return useMutation({
    mutationKey: "download-project-document",
    mutationFn: downloadProjectFiles,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "download-project-document",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "download-project-document",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useCreateProjectDocument() {
  return useMutation({
    mutationKey: "create-project-document",
    mutationFn: createProjectDocument,
    onSuccess: () => {
      queryClient.invalidateQueries("get-project-documents");
      handleEventForTracking({
        eventName: "create-project-document",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "create-project-document",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useDeleteTaskDocument() {
  return useMutation({
    mutationKey: "delete-task-document",
    mutationFn: deleteTaskDocument,
    onSuccess: () => {
      queryClient.invalidateQueries("get-task-documents");
      handleEventForTracking({
        eventName: "delete-task-document",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "delete-task-document",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useDeleteProjectDocument() {
  return useMutation({
    mutationKey: "delete-project-document",
    mutationFn: deleteProjectDocument,
    onSuccess: () => {
      queryClient.invalidateQueries("get-project-documents");
      handleEventForTracking({
        eventName: "delete-project-document",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "delete-project-document",
        success: false,
        eventType: "API",
      });
    },
  });
}
